import { Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { FadeInStack } from "@pd/components/FadeComponents";
import Footer from "@pd/layouts/BuyerCredit/components/Footer";
import { useDispatch } from "react-redux";
import creditApplicationActions from "@pd/layouts/BuyerCredit/redux/actions/creditApplication";
import { useAppSelector } from "@pd/redux/store";
import { selectMerchantName } from "@pd/layouts/BuyerCredit/redux/selectors/auth";
import { useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import StitchAsyncButton from "@pd/components/StitchAsyncButton";
import AppIncompleteModal from "@pd/layouts/BuyerCredit/components/AppIncompleteModal";
import PlaidLinkManager from "@pd/layouts/BuyerCredit/components/PlaidLinkManager";

export default function LinkBank() {
  const dispatch = useDispatch();

  // The remountKey on the PlaidLinkButton is necessary to force the
  // Plaid link component to remount. Without remounting, the Plaid
  // iframe will only show the first time startPlaidLink() is called,
  // and not on subsequent calls after the modal is closed.
  const [remountKey, setRemountKey] = useState(1);
  const [showAppIncompleteModal, setShowIncompleteModal] = useState(false);
  const [showPlaidLinkModal, setShowPlaidLinkModal] = useState(false);

  const merchantName = useAppSelector(selectMerchantName);

  const onShowPlaidModalClick = () => {
    setShowPlaidLinkModal(true);
  };

  const handleAppIncompleteModalClose = () => {
    setShowIncompleteModal(false);
    dispatch(creditApplicationActions.submitCreditApplication());
  };

  const onPlaidLinkSuccess = (publicToken: string, accountIds: string[]) => {
    setShowPlaidLinkModal(false);
    dispatch(creditApplicationActions.setIsBankLinked(false));
    dispatch(
      creditApplicationActions.submitCreditApplication(publicToken, accountIds),
    );
  };

  const onPlaidLinkExit = () => {
    setShowPlaidLinkModal(false);
    dispatch(creditApplicationActions.setIsBankLinked(false));
    setShowIncompleteModal(true);
  };

  const onPlaidLinkRemount = () => {
    setRemountKey(remountKey + 1);
  };

  return (
    <FadeInStack
      spacing={3}
      sx={{
        height: "calc(100vh - 50px)",
        maxWidth: "600px",
      }}
    >
      <Stack
        spacing={3}
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography variant="h5">Your review</Typography>
        <Typography variant="h5">
          Verify your business standing for {merchantName} with a credit review.
        </Typography>
        <Typography>
          Continue to link your bank with Plaid for a review of your transaction
          history.{" "}
          <span style={{ textTransform: "capitalize" }}>{merchantName}</span>{" "}
          will not get access to your statement or account information through
          this review.
        </Typography>
      </Stack>
      <Footer>
        <Stack spacing={3}>
          <Stack>
            <Typography color="gray" fontFamily="CircularBold">
              I don&apos;t have a bank account I can link
            </Typography>
            <Typography variant="body1" color="gray">
              <span style={{ textTransform: "capitalize" }}>
                {merchantName}
              </span>{" "}
              will follow up on next steps if you are unable to link your bank
              account and may request a manual review of bank statements.
            </Typography>
          </Stack>
          <PlaidLinkManager
            key={remountKey}
            showModal={showPlaidLinkModal}
            onSuccess={onPlaidLinkSuccess}
            onExit={onPlaidLinkExit}
            onRemount={onPlaidLinkRemount}
          />
          <StitchAsyncButton
            buttonText="Continue"
            variant={"contained"}
            color={"primary"}
            logoColor={"black"}
            loadingSize={"small"}
            loading={false}
            loadingPosition={{
              top: 0,
              left: 0,
            }}
            success={false}
            onClick={onShowPlaidModalClick}
            style={{
              width: "100%",
              height: "56px",
              padding: "16px 24px",
              fontSize: "20px",
            }}
            endAdornment={<ArrowForward />}
          />
        </Stack>
      </Footer>
      {showAppIncompleteModal && (
        <Dialog open>
          <DialogContent
            sx={{
              p: 4,
              overflowX: "auto",
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "12px",
                height: "7px",
              },
              "::-webkit-scrollbar-track": {
                background: "#F1F1F1",
              },
              "::-webkit-scrollbar-thumb": {
                background: "#ddd",
              },
              "::-webkit-scrollbar-thumb:hover": {
                background: "#333",
              },
            }}
          >
            <AppIncompleteModal
              onClickPlaid={onShowPlaidModalClick}
              handleClose={handleAppIncompleteModalClose}
            />
          </DialogContent>
        </Dialog>
      )}
    </FadeInStack>
  );
}
